
var called = false;

function setupCardinalCommerce(){
    Cardinal.configure({
        maxRequestRetries: 1,
        logging : {
            level : 'on'
        }
    });

    Cardinal.on('payments.setupComplete', function(setupCompleteData) {
        console.log("setupComplete!!!" + setupCompleteData);
    });

    Cardinal.on("payments.validated", function(data, jwt) {
        console.log("ACTION CODE: " + data.ActionCode);
        if(called) return;
        switch (data.ActionCode) {
            case "SUCCESS":
                // Handle successful transaction, send JWT to backend to verify
                console.log("SUCCESS!");
                window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(data.ActionCode, data); });
                break;
            default:
                console.log("Resultado: " + data.ErrorDescription)
                window.angularComponentReference.zone.run(() => { window.angularComponentReference.loadAngularFunction(data.ActionCode, ''); });
                break;
        }
        called = true;
    });
}

function validatePayment(accountNumber,expirationMonth,expirationYear,cardCode,orderNumber,jWTContainer) {
    var orderObject = {
        "Authorization" : {
            "AuthorizeAccount" : false
        },
        "Cart" : [ {
            "Name" : "Transaction validation",
            "SKU" : "1",
            "Quantity" : "50",
            "Description" : "Transaction 50 CLP"
        } ],
        "Consumer" : {
            "Email1" : "",
            "Email2" : "",
            "ShippingAddress" : {
                "FullName" : "",
                "FirstName" : "",
                "MiddleName" : "",
                "LastName" : "",
                "Address1" : "",
                "Address2" : "",
                "Address3" : "",
                "City" : "",
                "State" : "",
                "PostalCode" : "",
                "CountryCode" : "",
                "Phone1" : "",
                "Phone2" : ""
            },
            "BillingAddress" : {
                "FullName" : "",
                "FirstName" : "",
                "MiddleName" : "",
                "LastName" : "",
                "Address1" : "",
                "Address2" : "",
                "Address3" : "",
                "City" : "",
                "State" : "",
                "PostalCode" : "",
                "CountryCode" : "",
                "Phone1" : "",
                "Phone2" : ""
            },
            "Account" : {
                "AccountNumber" : accountNumber,
                "ExpirationMonth" : expirationMonth,
                "ExpirationYear" : expirationYear,
                "CardCode" : cardCode
            }
        },
        "Options" : {
            "EnableCCA" : true
        },
        "OrderDetails" : {
            "OrderNumber" : orderNumber,
            "Amount" : "50",
            "CurrencyCode" : "152",
            "OrderChannel" : "S",
            "TransactionId" : ""
        },
        "Token" : {
            "Token" : "",
            "CardCode" : "",
            "ExpirationMonth" : "",
            "ExpirationYear" : ""
        }
    };

    Cardinal.setup("init", { jwt : jWTContainer });

    Cardinal.start("cca", orderObject);
    called = false;
}
